import React from 'react';
import {
  AboutUs,
  Chef,
  Contact,
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  Laurels,
  Menu,
  SpecialMenu,
  Jobs,
} from './container';
import { Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div>
      {/* <Navbar></Navbar> */}
      <Header></Header>
      {/* <AboutUs></AboutUs> */}
      {/* <Menu></Menu> */}
      {/* <Contact></Contact> */}
      {/* <Jobs></Jobs> */}
      {/* <Chef></Chef>
      <Intro></Intro>
      <Laurels></Laurels>
      <Gallery></Gallery>
      
      <Footer></Footer> */}
    </div>
  );
};

export default App;
